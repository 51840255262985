// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .custom-language-cs div {
  border: 1px solid #e9ebef;
  max-height: 39px;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  min-width: 135px;
} */
`, "",{"version":3,"sources":["webpack://./src/app/shared/JumboCustomizer/components/LocalizationOptions/local.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG","sourcesContent":["/* .custom-language-cs div {\n  border: 1px solid #e9ebef;\n  max-height: 39px;\n  border-radius: 8px;\n  padding: 5px 10px;\n  display: flex;\n  align-items: center;\n  min-width: 135px;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
