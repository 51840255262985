import GridViewIcon from "@mui/icons-material/GridView";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

export const libraryButtonsConfig = [
  {
    labelKey: "Sections",
    icon: <GridViewIcon style={{ fontSize: "30px", marginRight: "10px" }} />,
    path: "/sections",
    id: "sections",
  },
  {
    labelKey: "Messages",
    icon: (
      <MessageOutlinedIcon style={{ fontSize: "30px", marginRight: "10px" }} />
    ),
    path: "/messages",
    id: "messages",
  },
  {
    labelKey: "Keywords",
    icon: (
      <DescriptionOutlinedIcon
        style={{ fontSize: "30px", marginRight: "10px" }}
      />
    ),
    path: "/keywords",
    id: "keywords",
  },
];
