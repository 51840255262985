// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/./public/assets/images/connect-bg-img.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-account-btn {
    outline: 3px solid rgb(28 93 205 / 30%);
    border-radius: 10px;
    padding: 15px 30px;
    background: #2C73FF;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    transition: 0.2s;
    min-width: 210px;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;
}
.connect-wraper {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    min-height: 400px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/extension/check-profile/index.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,uBAAuB;AAC3B;AACA;IACI,yDAAiE;IACjE,2BAA2B;IAC3B,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".connect-account-btn {\n    outline: 3px solid rgb(28 93 205 / 30%);\n    border-radius: 10px;\n    padding: 15px 30px;\n    background: #2C73FF;\n    color: #fff;\n    font-size: 13px;\n    font-weight: 700;\n    text-transform: uppercase;\n    cursor: pointer;\n    border: none;\n    transition: 0.2s;\n    min-width: 210px;\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    justify-content: center;\n}\n.connect-wraper {\n    background-image: url(/./public/assets/images/connect-bg-img.png);\n    background-position: center;\n    background-size: cover;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 12px;\n    min-height: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
