import React, { useState, useEffect } from "react";
import "./Pricing.css";
import NovalyaLogo from "../../../../assets/img/novalya-logo.png";
import FIcon1 from "../../../../assets/img/f-icon-1.svg";
import FIcon2 from "../../../../assets/img/f-icon-2.svg";
import FIcon3 from "../../../../assets/img/f-icon-3.svg";
import FIcon4 from "../../../../assets/img/f-icon-4.svg";
import FIcon5 from "../../../../assets/img/f-icon-5.svg";
import FIcon6 from "../../../../assets/img/f-icon-6.svg";
import { getPlans } from "backendServices/ApiCalls";
import Spinner from "app/shared/Spinner";
import { useNavigate } from "react-router-dom";
import { getCompanyLogo } from "backendServices/ApiCalls";
import { Box, CircularProgress } from "@mui/material";

const PricingPlan = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const [loader, setLoader] = useState(false);
  const [plan1, setPlan1] = useState(null);
  const [plan2, setPlan2] = useState(null);
  const [plan3, setPlan3] = useState(null);
  const [currency, setCurrency] = useState("");
  const [month, setMonth] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);

  const calculateTimeLeft = () => {
    const difference = +new Date("2024-01-31T23:59:59") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [isDomainLoading, setIsDomainLoading] = React.useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const callPlansFunction =async () => {
    setLoader(true);

    const europeanCountryCodes = [
      "AT",
      "BE",
      "BG",
      "HR",
      "CY",
      "CZ",
      "DK",
      "EE",
      "FI",
      "FR",
      "DE",
      "GR",
      "HU",
      "IE",
      "IT",
      "LV",
      "LT",
      "LU",
      "MT",
      "NL",
      "PL",
      "PT",
      "RO",
      "SK",
      "SI",
      "ES",
      "SE",
    ];

    // const defaultLanguage = navigator.language;
    // const wordsArray = defaultLanguage?.split("-");

    // const regionCode = wordsArray[1];

    let regionCode=localStorage.getItem("regionCode")
    if(!regionCode){
      const res= await (await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=2e685b36ba4a4c5cb3645fc6baa56306')).json()
      
      regionCode=res?.country_code2;
      

      localStorage.setItem('regionCode',regionCode)
    }

    const isEuropean = europeanCountryCodes.includes(regionCode?.toUpperCase());

    let country;
    if (isEuropean) {
      country = "EUR";
      setCurrency("€");
      setMonth("mois");
    } else {
      country = "USD";
      setCurrency("$");
      setMonth("mo");
    }

    let p1 = "";
    let p2 = "";
    let p3 = "";
    let period = "month";

    if (isAnnual === true) {
      p1 = `Starter-${country}-Yearly`;
      p2 = `Unlimited-${country}-Yearly`;
      p3 = `Pro-${country}-Yearly`;
      period = "year";
    } else {
      p1 = `Starter-${country}-Monthly`;
      p2 = `Unlimited-${country}-Monthly`;
      p3 = `Pro-${country}-Monthly`;
      period = period;
    }
    let params = {
      period_unit: period,
      country: country,
    };
    getPlans(
      params,
      (response) => {
        let filteredArray1 = response?.data?.data?.list?.find(
          (obj) => obj?.item_price?.id === p1
        );
        if (filteredArray1) {
          setPlan1(filteredArray1);
        }

        let filteredArray2 = response?.data?.data?.list?.find(
          (obj) => obj?.item_price?.id === p2
        );
        if (filteredArray2) {
          setPlan2(filteredArray2);
        }

        let filteredArray3 = response?.data?.data?.list?.find(
          (obj) => obj?.item_price?.id === p3
        );
        if (filteredArray3) {
          setPlan3(filteredArray3);
        }

        setLoader(false);
      },
      (error) => {}
    );
  };
  const navigate = useNavigate();

  const handleBuyNow = (planId) => {
    localStorage.setItem("planId", planId);
    navigate("/signup");
    window.dataLayer.push({ event: "Abonnement" });
  };

  function getCompanyLogos() {
    if (window.location.hostname == "localhost") {
      var testDomainText = "admin-chroex.com";
      var testDomain = testDomainText.split(".");
    } else {
      var testDomain = window.location.hostname.split(".");
    }
    const domainParts = testDomain;

    if (domainParts.length >= 1 && domainParts[0]) {
      let final_domain;
      if (domainParts[0].includes("-")) {
        const parts = domainParts[0].split("-");
        final_domain = parts[1];
      } else {
        final_domain = domainParts[0];
      }

      getCompanyLogo({ domain: final_domain }).then((response) => {
        setIsDomainLoading(true);

        const favicon = document.querySelector('link[rel="icon"]');

        if (favicon && response.data.data.logo_val) {
          favicon.href = response.data.data.logo_val;
          setCompanyLogo(response.data.data.logo_val);
        }

        if (response.data.data.company) {
          var capitalizedCompany =
            response.data.data.company.charAt(0).toUpperCase() +
            response.data.data.company.slice(1);
          document.title = capitalizedCompany + "";
        }
        setIsDomainLoading(false);
      });
    }
  }

  useEffect(() => {
    callPlansFunction();
    getCompanyLogos();
  }, [isAnnual]);

  if (isDomainLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 16px" }} />
        {/* <h4>We are validating your request</h4> */}
      </Box>
    );
  }

  return (
    <div className="pricing-page">
      <div className="pricing-top">
        <div className="box">
          <div className="pt-box">
            <img src={companyLogo ? companyLogo : NovalyaLogo} alt="" />
            <h1>Choose The Plan That You Agrees With</h1>
            <h2>Start finding your customers today</h2>
            <div className="pt-price-box">
              <div className="annual-monthly-box">
                <span
                  onClick={() => {
                    setIsAnnual(true);
                  }}
                  className={isAnnual ? "active" : ""}
                >
                  Yearly
                  <i>30% OFF</i>
                </span>
                <span
                  onClick={() => {
                    setIsAnnual(false);
                  }}
                  className={!isAnnual ? "active" : ""}
                >
                  Monthly
                </span>
              </div>
              <div className="pt-grid">
                {loader ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="pt-grid-card starter">
                      {/* {isAnnual && <div className="annual-off">30% OFF</div>} */}
                      <center>
                        <h4>Starter</h4>
                      </center>
                      <p>for individuals and small teams</p>
                      <h3>
                        {isAnnual ? (
                          <>
                            {/* {currency}{((plan1?.item_price?.price / 100) / 12).toFixed(1)} <span>/ month</span>
                                                            <br></br>
                                                            {currency}{plan1?.item_price?.price / 100} <span>(billed yearly)</span> */}
                            {currency}
                            {33} <span>/ month</span>
                            <br></br>
                          </>
                        ) : (
                          <>
                            {currency}
                            {plan1?.item_price?.price / 100}{" "}
                            <span>/ month</span>
                          </>
                        )}
                      </h3>
                      <p>
                        {isAnnual ? (
                          <span>
                            {currency}
                            {396} billed yearly
                          </span>
                        ) : (
                          <span>billed monthly</span>
                        )}
                      </p>
                      <button
                        onClick={() => handleBuyNow(plan1.item_price.id)}
                        className="btn-outlined-purple"
                      >
                        Choose Starter
                      </button>
                    </div>
                    <div className="pt-grid-card unlimited">
                      {/* {isAnnual && <div className="annual-off">49% OFF</div>} */}
                      <div
                        style={{ textAlign: "center" }}
                        className="countdown"
                      >
                        <div>
                          <center>
                            <h2 style={{ color: "white", fontWeight: "bold" }}>
                              MOST POPULAR
                            </h2>
                          </center>
                          {/* <h5>
                                                            {timeLeft.hours} <span>Hours</span>
                                                        </h5>
                                                        <span>:</span>
                                                        <h5>
                                                            {timeLeft.minutes} <span>Minutes</span>
                                                        </h5>
                                                        <span>:</span>
                                                        <h5>
                                                            {timeLeft.seconds} <span>Seconds</span>
                                                        </h5> */}
                        </div>
                      </div>
                      {/*<img src={lifeTimeOfferImg} alt="" />*/}

                      <div className="unlimited-content">
                        <center>
                          <h4>Pro</h4>
                        </center>
                        <p>for larger teams and enterprices</p>
                        <h3>
                          {isAnnual ? (
                            <>
                              {/* <i>{currency}1234</i> <span style={{ fontSize: 14 }}>{currency}{((plan2?.item_price?.price / 100) / 12).toFixed(1)}</span><span>/ month</span>{" "}
                                                                <br></br>
                                                                {currency}{plan2?.item_price?.price / 100} <span>(billed yearly)</span> */}
                              {currency}
                              {62}
                              <span>/ month</span> <br></br>
                            </>
                          ) : (
                            <>
                              {currency}
                              {87} <span>/ month</span>{" "}
                            </>
                          )}
                        </h3>
                        <p>
                          {isAnnual ? (
                            <span>
                              {currency}
                              {744} billed yearly
                            </span>
                          ) : (
                            <span>billed monthly</span>
                          )}
                        </p>

                        <button
                          onClick={() => handleBuyNow(plan3.item_price.id)}
                          className="btn-outlined-purple btn-grd"
                        >
                          Choose Pro
                        </button>

                        {/* <i>* Lifetime offer is only for Unlimited Package</i> */}
                      </div>
                    </div>
                    <div className="pt-grid-card pro">
                      {/* {isAnnual && <div className="annual-off">30% OFF</div>} */}
                      <center>
                        <h4>Unlimited</h4>
                      </center>
                      <p>for growing teams</p>
                      <h3>
                        {isAnnual ? (
                          <>
                            {/* {currency}{((plan3?.item_price?.price / 100) / 12).toFixed(1)} <span>/ month</span>
                                                            <br></br>
                                                            {currency}{plan3?.item_price?.price / 100} <span>(billed yearly)</span> */}
                            {currency}
                            {99} <span>/ month</span>
                          </>
                        ) : (
                          <>
                            {currency}
                            {147} <span>/ month</span>
                          </>
                        )}
                      </h3>
                      <p>
                        {isAnnual ? (
                          <span>
                            {currency}
                            {1188} billed yearly
                          </span>
                        ) : (
                          <span>billed monthly</span>
                        )}
                      </p>
                      <button
                        onClick={() => handleBuyNow(plan2.item_price.id)}
                        className="btn-outlined-purple"
                      >
                        Choose Unlimited
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="features-grid">
              <div className="featured-card">
                <img src={FIcon1} alt="" />
                <p>No commitment</p>
              </div>
              <div className="featured-card">
                <img src={FIcon2} alt="" />
                <p>Multilingual customer support</p>
              </div>
              <div className="featured-card">
                <img src={FIcon3} alt="" />
                <p>Secure payment</p>
              </div>
              <div className="featured-card">
                <img src={FIcon4} alt="" />
                <p>Secure personal data</p>
              </div>
              <div className="featured-card">
                <img src={FIcon5} alt="" />
                <p>Immediate account activation</p>
              </div>
              <div className="featured-card">
                <img src={FIcon6} alt="" />
                <p>Availability worldwide</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="comparison-section">
        <div className="box">
          <h1>Compare Packages</h1>
          <div className="comparison-box">
            <div className="comparison-grid comparison-head">
              <div className="comparison-card">Comparison Chart</div>
              <div className="comparison-card">Starter</div>
              <div className="comparison-card">Pro</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Plans Price Monthly</div>
              <div className="comparison-card">
                {currency}47/{month}
              </div>
              <div className="comparison-card">
                {currency}87/{month}
              </div>
              <div className="comparison-card">
                {currency}147/{month}
              </div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Plans Price Annual 30% off</div>
              <div className="comparison-card">
                {currency}33/{month} <br></br> ({currency}396 billed yearly)
              </div>
              <div className="comparison-card">
                {currency}62/{month} <br></br> ({currency}744 billed yearly)
              </div>
              <div className="comparison-card">
                {currency}99/{month} <br></br> ({currency}1188 billed yearly)
              </div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Auto Comment with AI</div>
              <div className="comparison-card">5/day</div>
              <div className="comparison-card">20/day</div>
              <div className="comparison-card">50/day</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">
                Connexion with new prospects
              </div>
              <div className="comparison-card">20/day</div>
              <div className="comparison-card">50/day</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Saved Facebook Groups</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">
                Auto message on Friend Requests
              </div>
              <div className="comparison-card">10/day</div>
              <div className="comparison-card">25/day</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Smart Messages Algorithme</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Delete Pending Requests</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Auto Birthday’s Messages</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Wish Yesterday Birthdays</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Wish 2 Days ago Birthdays</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Contacts</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Tags</div>
              <div className="comparison-card">3</div>
              <div className="comparison-card">5</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Steps</div>
              <div className="comparison-card">10</div>
              <div className="comparison-card">50</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Messages</div>
              <div className="comparison-card">20/day</div>
              <div className="comparison-card">50/day</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">NovaData Usage</div>
              <div className="comparison-card">1 time /mo</div>
              <div className="comparison-card">2 time /mo</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Add to Whitelist</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Auto Unfriend</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Delete Deactivated friends</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Support Email</div>
              <div className="comparison-card">Yes</div>
              <div className="comparison-card">Yes</div>
              <div className="comparison-card">Yes</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">
                1 Hour Startup Support on Zoom (in a small group)
              </div>
              <div className="comparison-card">Free</div>
              <div className="comparison-card">Free</div>
              <div className="comparison-card">Free</div>
            </div>
            <div className="comparison-grid comparison-grid-unlimited">
              <div className="comparison-card"></div>
              <div className="comparison-card">
                <button
                  onClick={() => handleBuyNow(plan1.item_price.id)}
                  className="btn-outlined-purple"
                >
                  Choose Starter
                </button>
              </div>
              <div className="comparison-card">
                <button
                  onClick={() => handleBuyNow(plan2.item_price.id)}
                  className="btn-outlined-purple"
                >
                  Choose Pro
                </button>
              </div>
              <div className="comparison-card btn-unlimited">
                <button
                  onClick={() => handleBuyNow(plan3.item_price.id)}
                  className="btn-outlined-purple btn-grd "
                >
                  Choose Unlimited
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
