// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .MuiTextField-root { */
.css-1e7mi5c-MuiDataGrid-root .MuiTextField-root {
  width: 320px !important;
  position: absolute !important;
  top: -36px !important;
  left: 0px !important;
}
.nova-box .MuiInputBase-root::before {
  border: 1px solid #e0e1e3 !important;
  height: 32px !important;
  border-radius: 4px;
}
.nova-box .MuiInputBase-root {
  padding: 2px 15px !important;
}
.btn-group-friends-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: max-content;
  margin-left: auto;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/extension/novadata/Table.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,uBAAuB;EACvB,6BAA6B;EAC7B,qBAAqB;EACrB,oBAAoB;AACtB;AACA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,QAAQ;AACV","sourcesContent":["/* .MuiTextField-root { */\n.css-1e7mi5c-MuiDataGrid-root .MuiTextField-root {\n  width: 320px !important;\n  position: absolute !important;\n  top: -36px !important;\n  left: 0px !important;\n}\n.nova-box .MuiInputBase-root::before {\n  border: 1px solid #e0e1e3 !important;\n  height: 32px !important;\n  border-radius: 4px;\n}\n.nova-box .MuiInputBase-root {\n  padding: 2px 15px !important;\n}\n.btn-group-friends-list {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  width: max-content;\n  margin-left: auto;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
