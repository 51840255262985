import "./index.css";
import image from "./nw-topbar-bell.png";
import { useTranslation } from "react-i18next";

export function Topbar() {
  const { t } = useTranslation();
  return (
    <div className="nw-header-top">
      <div className="nw-header-top-left">
        <img src={image} />
        <span>{t("pages.title.New Launch Instagram V2!")}</span>
      </div>
      <div className="nw-header-top-right">
        <span>{t("pages.title.Join us live on Monday 15th")}</span>
        <a className="nw-topbar-yellow-btn" href= {t("pages.title.new_insta_url")} target="_blank">
          {t("pages.title.Register")}
          <svg
            width="9"
            height="8"
            viewBox="0 0 9 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.7"
              d="M1 7L4 4L1 1"
              stroke="black"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              opacity="0.7"
              d="M5 7L8 4L5 1"
              stroke="black"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
