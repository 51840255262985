// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiInputBase-adornedEnd {
  border: solid 1px #e0e1e3;
  /* background-color: #dedaeb; */
  color: #8a8c8d;
  padding-right: 30px;
}

.MuiCardHeader-title {
  padding-left: 8rem;
  font-size: 60px;
}

.css-1qxrzhd-MuiButtonBase-root-MuiButton-root {
  background-color: black;
}
.library-tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  gap: 20%;
  margin-top: 3px;
}

.library-tabs > div {
  grid-template-columns: 1fr 1fr 1fr !important;
  margin: 0 !important;
  flex-grow: 1;
}
.library-tabs .top-header {
  margin-bottom: 3px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/extension/sections/section.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,kBAAkB;EAClB,8BAA8B;EAC9B,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,6CAA6C;EAC7C,oBAAoB;EACpB,YAAY;AACd;AACA;EACE,6BAA6B;AAC/B","sourcesContent":[".MuiInputBase-adornedEnd {\n  border: solid 1px #e0e1e3;\n  /* background-color: #dedaeb; */\n  color: #8a8c8d;\n  padding-right: 30px;\n}\n\n.MuiCardHeader-title {\n  padding-left: 8rem;\n  font-size: 60px;\n}\n\n.css-1qxrzhd-MuiButtonBase-root-MuiButton-root {\n  background-color: black;\n}\n.library-tabs {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  margin-bottom: 8px;\n  justify-content: space-between;\n  gap: 20%;\n  margin-top: 3px;\n}\n\n.library-tabs > div {\n  grid-template-columns: 1fr 1fr 1fr !important;\n  margin: 0 !important;\n  flex-grow: 1;\n}\n.library-tabs .top-header {\n  margin-bottom: 3px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
