import React, { useEffect, useState } from "react";
import routes from "./routes";
import useJumboRoutes from "@jumbo/hooks/useJumboRoutes";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useJumboTheme } from "@jumbo/hooks";
import { UpdateExtensionModelState } from "./pages/extension/extension-dashboard/UpdateExtensionModal";
import { ADMIN_BASE_URL } from "backendServices/ApiCalls";
import axios from "axios";

const AppRoutes = () => {
  const appRoutes = useJumboRoutes(routes);
  const { isLoading } = useJumboAuth();
  const { theme } = useJumboTheme();
const [isOpen,setIsOpen]=useState(true)
const [values,setValues] = useState('')
  const [storedLocale, setStoredLocale] = useState(localStorage.getItem("selectedLocale"));
  const currentPath = window.location.pathname; 
  useEffect(() => {
    let isMounted = true;

    const updateLocale = () => {
      if (isMounted) {
        setStoredLocale(localStorage.getItem("selectedLocale"));
      }
    };

    const timer = setTimeout(updateLocale, 300);

    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [theme]);
useEffect(()=>{
  setIsOpen(true)
},[currentPath])

useEffect(() => {
  fetchExtensionVersion()
}, [])

function fetchExtensionVersion() {
  axios
    .get(`${ADMIN_BASE_URL}/getadminaccountsettings`, {})
    .then((response) => {
      if (response?.data?.status === "success") {
        const result = response?.data?.data[0];
        if (result) {
          setValues({
            extension_version: result?.extension_version.trim(),
          });
        }
      }
    })
    .catch((error) => {});
}

  return <React.Fragment>
  <UpdateExtensionModelState setIsOpen={setIsOpen} values={values} isOpen={isOpen}/>         
  {isLoading ? "Loading" : appRoutes}
  </React.Fragment>;
};

export default AppRoutes;