import * as React from 'react';
import Paper from '@mui/material/Paper';
import Div from "@jumbo/shared/Div";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";

const Cryptoportfolio = () => {
  return (
    <div>Cryptoportfolio</div>
  )
}

export default Cryptoportfolio