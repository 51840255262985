import { useTranslation } from "react-i18next";
import {  useState } from "react";

export function ThirdStep({ onChange, keywordData, media, groupData }) {
  const { t } = useTranslation();
  const today = new Date();
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + 5);

  const formattedToday = futureDate.toISOString().substr(0, 10);
  const [gender, setGender] = useState(groupData.gender ?? "male");
  const [keyword, setKeyWords] = useState(groupData.keyword ?? 0);
  const [prospect, setProspectData] = useState(groupData.prospect ?? "no");
  const [socialAccount, setSocialAccount] = useState(media ?? "Facebook");
  const [datevalue, setDateValue] = useState(
    groupData.datevalue ?? formattedToday
  );
  const [selectedinterval, setSelectedInterval] = useState(
    groupData.selectedinterval ?? "90"
  );

  const [search_index, setSearchIndex] = useState(groupData.search_index ?? 1);
  const [launch_from, setLaunchFrom] = useState(
    groupData.search_index === 1 ? "no" : t("pages.title.custom")
  );

  let genders = [
    {
      value: "male",
      text: `${t("pages.title.male")}`,
      image: `<svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 1.47266L13.4816 7.6482M20 1.47266V7.01151M20 1.47266H14.1536M8.30719 19.4727C10.2452 19.4727 12.1038 18.7433 13.4742 17.445C14.8445 16.1467 15.6144 14.3859 15.6144 12.5499C15.6144 10.7138 14.8445 8.95297 13.4742 7.6547C12.1038 6.35642 10.2452 5.62706 8.30719 5.62706C6.3692 5.62706 4.51059 6.35642 3.14023 7.6547C1.76986 8.95297 1 10.7138 1 12.5499C1 14.3859 1.76986 16.1467 3.14023 17.445C4.51059 18.7433 6.3692 19.4727 8.30719 19.4727Z"
            stroke="#170F49"
            strokeWidth="1.65"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>`,
    },
    {
      value: "female",
      text: `${t("pages.title.female")}`,
      image: `<svg
          width="16"
          height="23"
          viewBox="0 0 16 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.69439 15.0441C11.442 15.0441 14.4801 12.006 14.4801 8.25835C14.4801 4.51072 11.442 1.47266 7.69439 1.47266C3.94675 1.47266 0.908691 4.51072 0.908691 8.25835C0.908691 12.006 3.94675 15.0441 7.69439 15.0441Z"
            stroke="#4C4C4C"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.6945 15.0439V21.4725M10.2838 18.6154H5.10522"
            stroke="#4C4C4C"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>`,
    },
    {
      value: "both",
      text: `${t("pages.title.both")}`,
      image: `<svg
              className="gender-both"
              width="20"
              height="25"
              viewBox="0 0 19 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 0V1.85185H15.594L11.187 5.93148C9.98238 5.0857 8.51178 4.62845 7 4.62963C3.145 4.62963 0 7.54167 0 11.1111C0 14.3667 2.617 17.0537 6 17.5056V20.3704H3V22.2222H6V25H8V22.2222H11V20.3704H8V17.5056C11.383 17.0537 14 14.3667 14 11.1111C14.0015 9.711 13.5076 8.34897 12.594 7.23333L17 3.15463V6.48148H19V0H12ZM7 6.48148C9.773 6.48148 12 8.54352 12 11.1111C12 13.6787 9.773 15.7407 7 15.7407C4.227 15.7407 2 13.6787 2 11.1111C2 8.54352 4.227 6.48148 7 6.48148Z"
                fill="#4C4C4C"
              />
            </svg>`,
    },
  ];

  let fromWhenOption = [
    { value: "90", text: `${t("pages.title.3months")}` },
    { value: "180", text: `${t("pages.title.6months")}` },
    { value: "360", text: `${t("pages.title.1year")}` },
    { value: "alltime", text: `${t("pages.title.alltime")}` },
    { value: t("pages.title.custom"), text: `${t("pages.title.custom")}` },
  ];

  let RetargetOption = [
    { value: "no", text: `${t("pages.title.no")}` },
    { value: "yes", text: `${t("pages.title.yes")}` },
  ];

  let searchOptions = [
    { value: "no", text: t("pages.title.from_start") },
    { value: t("pages.title.custom"), text: t("pages.title.custom") },
  ];

  const handleGenderChange = (value) => {
    setGender(value);
    handleChange(
      value,
      keyword,
      prospect,
      selectedinterval,
      datevalue,
      search_index
    );
 
  };
  const handleKeywordChange = (value) => {
    setKeyWords(value);
    handleChange(
      gender,
      value,
      prospect,
      selectedinterval,
      datevalue,
      search_index
    );

  };

  const handleProspectChange = (value) => {
    setProspectData(value);
    handleChange(
      gender,
      keyword,
      value,
      selectedinterval,
      datevalue,
      search_index
    );
   
  };

  const handleIntervalChange = (e) => {
    const intervalValue = e.target.value;
    setSelectedInterval(intervalValue);
    handleChange(
      gender,
      keyword,
      prospect,
      intervalValue,
      datevalue,
      search_index
    );
   
  };
  const handleCustomDateChange = (e) => {
    const newDateValue = e.target.value;
    setDateValue(newDateValue);
    handleChange(
      gender,
      keyword,
      prospect,
      selectedinterval,
      newDateValue,
      search_index
    );
  };

  const handleChange = (
    gender,
    keyword,
    prospect,
    selectedinterval,
    datevalue,
    search_index
  ) => {
    let stateData = {
      gender: gender,
      keyword: keyword,
      prospect: prospect,
      selectedinterval: selectedinterval,
      datevalue: datevalue,
    };

    if (media === "Instagram") {
      stateData.search_index = search_index;
    }
    onChange(stateData);
  };

  const handleLaunchChange = (value) => {
    setLaunchFrom(value);
    const searchIndexValue = value === "no" ? 1 : search_index;
    setSearchIndex(searchIndexValue);
    handleChange(
      gender,
      keyword,
      prospect,
      selectedinterval,
      datevalue,
      searchIndexValue
    );
  };

  const handleCustomIndexChange = (e) => {
    setSearchIndex(e.target.value);
    let value = e.target.value;
    if (!isNaN(value)) {
      value = Math.max(parseInt(value), 0);
      setSearchIndex(value);

      handleChange(
        gender,
        keyword,
        prospect,
        selectedinterval,
        datevalue,
        value
      );
    }
  };

  const formatDate = (date) => {
    return date !== "Invalid Date" &&
      date !== "" &&
      date instanceof Date &&
      !isNaN(date)
      ? date.toISOString().split("T")[0]
      : "";
  };

  return (
    <div className="col-1-3">
      {media !== "Instagram" && (
        <div className="col_1 ctm-col-wraper">
          <span className="title-18">{t("pages.title.Gender")}</span>
          <div className="message-right">
            {genders.map((item, index) => {
              return (
                <label className="group-items" htmlFor={item.value} key={index}>
                  <input
                    type="radio"
                    id={item.value}
                    name="gender"
                    defaultChecked={item.value === gender ? true : false}
                    onClick={() => handleGenderChange(item.value)}
                  />
                  <div className="group-wraper-right">
                    <span
                      dangerouslySetInnerHTML={{ __html: item.image }}
                    ></span>
                    <span className="group-name">{item.text}</span>
                  </div>
                </label>
              );
            })}
          </div>
        </div>
      )}

      {socialAccount === "Facebook" ? (
        <div className="col_1 ctm-col-wraper">
          <span className="title-18">{t("pages.title.Keywords")}</span>
          <div className="message-right">
            {keywordData.map((item, index) => (
              <label
                className="group-items"
                htmlFor={`${item.name}-${index}`}
                key={index}
              >
                <input
                  type="radio"
                  id={`${item.name}-${index}`}
                  name="keywords"
                  checked={item.id === (keyword?parseInt(keyword):0) ? true : false}
                  onChange={() => handleKeywordChange(item.id)}
                />
                <div className="group-wraper-right">
                  <span className="group-name">{item.name}</span>
                </div>
              </label>
            ))}
          </div>
        </div>
      ) : null}
      <div className="col_1">
        <div className="col_1 ctm-col-wraper">
          <span className="title-18">
            {t("pages.title.retarget_same_user")}
          </span>
          <div className="lunch-wraper">
            {RetargetOption.map((item, index) => {
              return (
                <label
                  className="group-items"
                  htmlFor={`lunch-${index}`}
                  key={index}
                >
                  <input
                    type="radio"
                    id={`lunch-${index}`}
                    name="lunch"
                    checked={item.value === prospect ? true : false}
                    onChange={() => handleProspectChange(item.value)}
                  />
                  <div className="group-wraper-right">
                    <span className="group-name">{item.text}</span>
                  </div>
                </label>
              );
            })}
          </div>
        </div>
        {prospect === "yes" ? (
          <div className="col_1 keywords-wraper">
            <span className="title-18"></span>
            <select
              className="step-select"
              onChange={handleIntervalChange}
              value={selectedinterval}
            >
              {fromWhenOption.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.text}
                </option>
              ))}
            </select>
          </div>
        ) : null}

        {selectedinterval === t("pages.title.custom") && prospect === "yes" ? (
          <div className="col_1 keywords-wraper w-100">
            <span className="title-18"></span>
            <input
              className="step-select"
              type="date"
              name="search_index"
              value={formatDate(new Date(datevalue)) || ""}
              onChange={handleCustomDateChange}
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        ) : null}
      </div>
      {media === "no" ? (
        <div className="col_1">
          <div className="col_1 ctm-col-wraper">
            <span className="title-18">{t("pages.title.launch_from")}</span>
            <div className="lunch-wraper">
              {searchOptions.map((item, index) => {
                return (
                  <label
                    className="group-items"
                    htmlFor={`lunch-from-${index}`}
                    key={`lunch-from-${index}`}
                  >
                    <input
                      type="radio"
                      id={`lunch-from-${index}`}
                      name="lunch-from"
                      checked={item.value === launch_from ? true : false}
                      onChange={() => handleLaunchChange(item.value)}
                    />
                    <div className="group-wraper-right">
                      <span className="group-name">{item.text}</span>
                    </div>
                  </label>
                );
              })}
            </div>
            {launch_from === t("pages.title.custom") ? (
              <div className="col_1 keywords-wraper w-100">
                <span className="title-18"></span>
                <input
                  className="step-select"
                  type="number"
                  name="search_index"
                  value={search_index}
                  placeholder="Enter Custom Value"
                  onChange={handleCustomIndexChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}
