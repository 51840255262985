import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import {
  Box,
  Button,
  TextField,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  createKeyword,
  fetchAllKeywordList,
} from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

const CreateKeyword = () => {
  const { t } = useTranslation();
  const [alertData, setAlertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [typefor, setTypefor] = useState();

  const navigate = useNavigate();
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
  });
  const [keywordData, setKeywordData] = useState([]);
  const type = [
    { value: "facebook", icon: <FacebookIcon /> },
    {
      value: "instagram",
      icon: <InstagramIcon />,
    },
  ];
  const [values, setValues] = React.useState({
    name: "",
    positive_keywords: [],
    negative_keyword: [],
    positive_value: "",
    negative_value: "",
  });

  useEffect(() => {
    fetchAllKeywordList(
      {},
      (response) => {
        if (response?.data?.data) setKeywordData(response?.data?.data ?? []);
      },
      (error) => {}
    );
  }, []);

  const handleDeleteValue = (valueToDelete) => {
    setValues((prevValues) => ({
      ...prevValues,
      positive_keywords: prevValues.positive_keywords.filter(
        (_, index) => index !== valueToDelete
      ),
    }));
  };

  const checkAlreadyExistKeyword = (newKeyword) => {
    const allKeywords = [
      ...values.positive_keywords,
      ...values.negative_keyword,
    ];
    const result = allKeywords.find((keyword) => keyword === newKeyword);
    if (result) {
      setAlertData({
        show: true,
        message: <span>{`${t("pages.title.Keyword already exist")}`}</span>,
        variant: "error",
      });
      return true;
    }
  };

  const handleInputChange = (event) => {
    if (
      (event.key === "," || event.key === "Enter" || event.key === "Tab") &&
      values.positive_value.trim() !== ""
    ) {
      event.preventDefault();
      const newKeyword = values.positive_value.trim();
      const result = checkAlreadyExistKeyword(newKeyword);
      if (result) return;
      setValues((prevValues) => ({
        ...prevValues,
        positive_keywords: [
          ...prevValues.positive_keywords,
          prevValues.positive_value,
        ],
        positive_value: "",
      }));
    }
  };

  const handleDeleteNegativeValue = (valueToDelete) => {
    setValues((prevValues) => ({
      ...prevValues,
      negative_keyword: prevValues.negative_keyword.filter(
        (_, index) => index !== valueToDelete
      ),
    }));
  };

  const handleNegativeInputChange = (event) => {
    if (
      (event.key === "," || event.key === "Enter" || event.key === "Tab") &&
      values.negative_value.trim() !== ""
    ) {
      event.preventDefault();
      const newKeyword = values.negative_value.trim();
      const result = checkAlreadyExistKeyword(newKeyword);
      if (result) return;
      setValues((prevValues) => ({
        ...prevValues,
        negative_keyword: [
          ...prevValues.negative_keyword,
          prevValues.negative_value,
        ],
        negative_value: "",
      }));
    }
  };

  const onSubmitForm = (data, setSubmitting) => {
    const trimmedName = data.name.trim();

    if (!trimmedName) {
      setAlertData({
        show: true,
        message: "Name is required",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }
    if (keywordData.some((keyword) => keyword.name === trimmedName)) {
      setAlertData({
        show: true,
        message: "Keyword already exist",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }
    let params = {
      name: data.name,
      positive_keyword:
        values.positive_keywords.length > 0
          ? JSON.stringify(values.positive_keywords)
          : null,
      negative_keyword:
        values.negative_keyword.length > 0
          ? JSON.stringify(values.negative_keyword)
          : null,
      types: typefor,
    };

    if (params.positive_keyword === null && params.negative_keyword === null) {
      setAlertData({
        show: true,
        message: (
          <span>{`${t("pages.title.Please add atleast one keyword")}`}</span>
        ),
        variant: "error",
      });
      setSubmitting(false);
      return;
    }

    createKeyword(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setAlertData({
            show: true,
            message: (
              <span>{`${t("pages.title.Keyword Created Successfully")}`}</span>
            ),
            variant: "success",
          });
          setSubmitting(false);
          navigate("/keywords");
        }
      },
      (error) => {
        setAlertData({
          show: true,
          message: <span>{error?.response?.data?.message}</span>,
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  return (
    <Box className="outer-contaier message-container  create-keywords-wraper">
      <Box sx={{ m: 3 }}>
        <Box className="message-header">
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("pages.title.create_keyword")}
          </Typography>
          <Button variant="outlined" onClick={() => navigate("/keywords")}>
            <ArrowBackIosOutlinedIcon sx={{ height: "50px" }} />
          </Button>
        </Box>
        <List disablePadding>
          <Formik
            validateOnChange={true}
            enableReinitialize={true}
            initialValues={{
              name: "",
              positive_keywords: [],
              negative_keyword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSubmitForm(data, setSubmitting);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Box gap={2} sx={{ display: "grid", padding: "16px 24px 0" }}>
                  <Box
                    sx={{ display: "grid", gap: "10px" }}
                    className="keywords-sec-only"
                  >
                    <Box className="key-form-group">
                      <p className="msg-label msg-label-space">
                        Name of your Keywords
                      </p>
                      <JumboTextField
                        fullWidth
                        placeholder={"Name"}
                        name="name"
                        type="text"
                      />
                    </Box>
                    <Box className="key-form-group key-form-tags">
                      <p className="msg-label msg-label-space">
                        Positive Keywords
                      </p>
                      <Box>
                        {values.positive_keywords.length > 0 ? (
                          <Box
                            sx={{
                              border: "1px solid #c4c4c4",
                              mt: 0.5,
                              p: 1,
                              borderRadius: 1,
                            }}
                          >
                            {values.positive_keywords.map((keyword, index) => (
                              <Chip
                                key={index}
                                label={keyword}
                                onDelete={() => handleDeleteValue(index)}
                                variant="outlined"
                                sx={{ margin: 0.5 }}
                              />
                            ))}
                          </Box>
                        ) : (
                          ""
                        )}
                        <TextField
                          fullWidth
                          placeholder="Enter or paste, separated by commas"
                          variant="outlined"
                          onKeyDown={handleInputChange}
                          value={values.positive_value || ""}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              positive_value: e.target.value,
                            })
                          }
                        />
                      </Box>
                    </Box>
                    <Box className="key-form-group key-form-tags">
                      <p className="msg-label msg-label-space">
                        Negative Keywords
                      </p>
                      <Box>
                        {values.negative_keyword.length > 0 ? (
                          <Box
                            sx={{
                              border: "1px solid #c4c4c4",
                              mt: 0.5,
                              p: 1,
                              borderRadius: 1,
                            }}
                          >
                            {values.negative_keyword.map((keyword, index) => (
                              <Chip
                                key={index}
                                label={keyword}
                                onDelete={() =>
                                  handleDeleteNegativeValue(index)
                                }
                                variant="outlined"
                                sx={{ margin: 0.5 }}
                              />
                            ))}
                          </Box>
                        ) : (
                          ""
                        )}
                        <TextField
                          fullWidth
                          placeholder="Enter or paste, separated by commas"
                          variant="outlined"
                          onKeyDown={handleNegativeInputChange}
                          value={values.negative_value || ""}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              negative_value: e.target.value,
                            })
                          }
                        />
                      </Box>
                    </Box>
                    <Box className="key-form-group key-form-group-btm">
                      <p className="msg-label msg-label-space">Select Type</p>
                      <Box
                        item
                        xs={12}
                        sx={{
                          padding: "8px !important",
                          backgroundColor: "#F6F6F6",
                        }}
                      >
                        <ToggleButtonGroup
                          className="keyword-group-new"
                          color="primary"
                          value={typefor}
                          onChange={(event, value) => setTypefor(value)}
                          aria-label="Platform"
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            gap: 1,
                            "& .Mui-selected": {
                              backgroundColor: "#41CE81 !important",
                              color: "white !important",
                            },
                            button: {
                              borderRadius: "8px !important",
                            },
                          }}
                        >
                          {type.map((v, index) => {
                            return (
                              <ToggleButton
                                value={v.value}
                                key={index}
                                sx={{ gap: 1 }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>{v.icon}</div> <div>Prospection</div>
                                </span>
                              </ToggleButton>
                            );
                          })}
                        </ToggleButtonGroup>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="keyword-footer"
                    sm={4}
                    sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                  >
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      id="verify-url-fb-group"
                      size="large"
                      loading={isSubmitting}
                    >
                      <span>{t("pages.title.submit")}</span>
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </List>
        <div>
          {" "}
          {alertData.show ? (
            <SweetAlert alertData={alertData} setalertData={setAlertData} />
          ) : (
            ""
          )}
        </div>
      </Box>
    </Box>
  );
};

export default CreateKeyword;
