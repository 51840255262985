import React, { Component, createContext, useContext, useEffect } from "react";

const ErrorBoundaryContext = createContext();

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryContext.Provider value={true}>
          {this.props.children}
        </ErrorBoundaryContext.Provider>
      );
    }

    return (
      <ErrorBoundaryContext.Provider value={false}>
        {this.props.children}
      </ErrorBoundaryContext.Provider>
    );
  }
}

const ErrorBoundaryWrapper = ({ children }) => {
  const hasError = useContext(ErrorBoundaryContext);

  useEffect(() => {
    if (hasError) {
      window.location.href = "/error";
    }
  }, [hasError]);

  return hasError ? null : children;
};

export { ErrorBoundary, ErrorBoundaryWrapper };
export default ErrorBoundary;
