import React, { useState, useEffect, useContext } from "react";
import lifeTimeOfferImg from "../../../assets/img/lifetime-offer.png";
import "./Pricing.css";
import FIcon1 from "../../../assets/img/f-icon-1.svg";
import FIcon2 from "../../../assets/img/f-icon-2.svg";
import FIcon3 from "../../../assets/img/f-icon-3.svg";
import FIcon4 from "../../../assets/img/f-icon-4.svg";
import FIcon5 from "../../../assets/img/f-icon-5.svg";
import FIcon6 from "../../../assets/img/f-icon-6.svg";
import {
  getupgradePlans,
  UpdateSubscriptionApi,
} from "backendServices/ApiCalls";
import Spinner from "app/shared/Spinner";
import { useNavigate } from "react-router-dom";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";

const Upgradepriceplan = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const [loader, setLoader] = useState(false);
  const [plan1, setPlan1] = useState(null);
  const [plan2, setPlan2] = useState(null);
  const [plan3, setPlan3] = useState(null);
  const [currency, setCurrency] = useState("");
  const [userPlanData, setUserPlanData] = useState(null);
  const [btn1Status, setBtn1Status] = useState(false);
  const [btn2Status, setBtn2Status] = useState(false);
  const [btn3Status, setBtn3Status] = useState(false);
  const { loginUserData } = useContext(CustomProvider);

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const calculateTimeLeft = () => {
    const difference = +new Date("2024-01-31T23:59:59") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const callPlansFunction = () => {
    setLoader(true);

    let country = loginUserData?.currency;
    if (country === "EUR") {
      setCurrency("€");
    } else {
      setCurrency("$");
    }

    let p1 = "";
    let p2 = "";
    let p3 = "";
    let period = "month";

    if (isAnnual === true) {
      p1 = `Starter-${country}-Yearly`;
      p2 = `Unlimited-${country}-Yearly`;
      p3 = `Pro-${country}-Yearly`;
      period = "year";
    } else {
      p1 = `Starter-${country}-Monthly`;
      p2 = `Unlimited-${country}-Monthly`;
      p3 = `Pro-${country}-Monthly`;
      period = period;
    }
    let params = {
      period_unit: period,
      country: country,
    };
    getupgradePlans(
      params,
      (response) => {
        setUserPlanData(response?.data?.planData);
        if (response?.data?.planData?.planid === "Starter" && !isAnnual) {
          setBtn1Status(true);
          setBtn2Status(false);
          setBtn3Status(false);
        } else if (
          response?.data?.planData?.planid === "Unlimited" &&
          !isAnnual
        ) {
          setBtn1Status(true);
          setBtn2Status(true);
          setBtn3Status(true);
        } else if (response?.data?.planData?.planid === "Pro" && !isAnnual) {
          setBtn1Status(true);
          setBtn2Status(true);
          setBtn3Status(false);
        } else {
          setBtn1Status(false);
          setBtn2Status(false);
          setBtn3Status(false);
        }
        let filteredArray1 = response?.data?.data?.list?.find(
          (obj) => obj?.item_price?.id === p1
        );
        if (filteredArray1) {
          setPlan1(filteredArray1);
        }

        let filteredArray2 = response?.data?.data?.list?.find(
          (obj) => obj?.item_price?.id === p2
        );
        if (filteredArray2) {
          setPlan2(filteredArray2);
        }

        let filteredArray3 = response?.data?.data?.list?.find(
          (obj) => obj?.item_price?.id === p3
        );
        if (filteredArray3) {
          setPlan3(filteredArray3);
        }

        setLoader(false);
      },
      (error) => {}
    );
  };
  const navigate = useNavigate();
  const handleBuyNow = (planId) => {
    let subscriptionId = loginUserData?.subscriptionId;
    let params = {
      item_price_id: planId,
      subscription_id: subscriptionId,
    };
    UpdateSubscriptionApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          window.location.replace(response?.data?.data?.hosted_page?.url);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (loginUserData) {
      callPlansFunction();
    }
  }, [isAnnual, loginUserData]);

  return (
    <div className="pricing-page">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <div className="pricing-top">
        <div className="box">
          <div className="pt-box">
            {/* <img src={NovalyaLogo} alt="" />
                        <h1>Choose The Plan That You Agrees With</h1>
                        <h2>Start finding your customers today</h2> */}
            <div className="pt-price-box">
              <div className="annual-monthly-box">
                <span
                  onClick={() => {
                    setIsAnnual(true);
                  }}
                  className={isAnnual ? "active" : ""}
                >
                  Annual
                  <i>30% OFF</i>
                </span>
                <span
                  onClick={() => {
                    setIsAnnual(false);
                  }}
                  className={!isAnnual ? "active" : ""}
                >
                  Monthly
                </span>
              </div>
              <div className="pt-grid">
                {loader ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="pt-grid-card starter">
                      {isAnnual && <div className="annual-off">30% OFF</div>}
                      <h4>Starter</h4>
                      <p>for individuals and small teams</p>
                      <h3>
                        {isAnnual ? (
                          <>
                            {currency}
                            {plan1?.item_price?.price / 100} <span>/ year</span>
                          </>
                        ) : (
                          <>
                            {currency}
                            {plan1?.item_price?.price / 100}{" "}
                            <span>/ month</span>
                          </>
                        )}
                      </h3>
                      <p>
                        {isAnnual ? (
                          <span>billed annually</span>
                        ) : (
                          <span>billed monthly</span>
                        )}
                      </p>
                      <button
                        disabled={btn1Status}
                        onClick={() => handleBuyNow(plan1?.item_price.id)}
                        className={`btn-outlined-purple ${
                          btn1Status && "faded-text"
                        }`}
                      >
                        Choose Starter
                      </button>
                    </div>
                    <div className="pt-grid-card unlimited">
                      {isAnnual && <div className="annual-off">49% OFF</div>}
                      <div className="countdown">
                        <h6>End of Sale in:</h6>
                        <div className="countdown-box">
                          <h5>
                            {timeLeft.hours} <span>Hours</span>
                          </h5>
                          <span>:</span>
                          <h5>
                            {timeLeft.minutes} <span>Minutes</span>
                          </h5>
                          <span>:</span>
                          <h5>
                            {timeLeft.seconds} <span>Seconds</span>
                          </h5>
                        </div>
                      </div>
                      <img src={lifeTimeOfferImg} alt="" />

                      <div className="unlimited-content">
                        <h4>Unlimited</h4>
                        <p>for larger teams and enterprices</p>
                        <h3>
                          {isAnnual ? (
                            <>
                              <i>{currency}1234</i> {currency}
                              {plan2?.item_price?.price / 100}{" "}
                              <span>/ year</span>{" "}
                            </>
                          ) : (
                            <>
                              <i>{currency}147</i> {currency}
                              {plan2?.item_price?.price / 100}{" "}
                              <span>/ month</span>{" "}
                            </>
                          )}
                        </h3>
                        <p>
                          {isAnnual ? (
                            <span>billed annually</span>
                          ) : (
                            <span>billed monthly</span>
                          )}
                        </p>

                        <button
                          disabled={btn2Status}
                          onClick={() => handleBuyNow(plan2?.item_price.id)}
                          className={`btn-outlined-purple btn-grd ${
                            btn2Status && "faded-text"
                          }`}
                        >
                          Choose Unlimited
                        </button>

                        <i>* Lifetime offer is only for Unlimited Package</i>
                      </div>
                    </div>
                    <div className="pt-grid-card pro">
                      {isAnnual && <div className="annual-off">30% OFF</div>}
                      <h4>Pro</h4>
                      <p>for growing teams</p>
                      <h3>
                        {isAnnual ? (
                          <>
                            {currency}
                            {plan3?.item_price?.price / 100} <span>/ year</span>
                          </>
                        ) : (
                          <>
                            {currency}
                            {plan3?.item_price?.price / 100}{" "}
                            <span>/ month</span>
                          </>
                        )}
                      </h3>
                      <p>
                        {isAnnual ? (
                          <span>billed annually</span>
                        ) : (
                          <span>billed monthly</span>
                        )}
                      </p>
                      <button
                        disabled={btn3Status}
                        onClick={() => handleBuyNow(plan3?.item_price.id)}
                        className={`btn-outlined-purple ${
                          btn3Status && "faded-text"
                        }`}
                      >
                        Choose Pro
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="features-grid">
              <div className="featured-card">
                <img src={FIcon1} alt="" />
                <p>No commitment</p>
              </div>
              <div className="featured-card">
                <img src={FIcon2} alt="" />
                <p>Multilingual customer support</p>
              </div>
              <div className="featured-card">
                <img src={FIcon3} alt="" />
                <p>Secure payment</p>
              </div>
              <div className="featured-card">
                <img src={FIcon4} alt="" />
                <p>Secure personal data</p>
              </div>
              <div className="featured-card">
                <img src={FIcon5} alt="" />
                <p>Immediate account activation</p>
              </div>
              <div className="featured-card">
                <img src={FIcon6} alt="" />
                <p>Availability worldwide</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="comparison-section">
        <div className="box">
          <h1>Compare Packages</h1>
          <div className="comparison-box">
            <div className="comparison-grid comparison-head">
              <div className="comparison-card">Comparison Chart</div>
              <div className="comparison-card">Starter</div>
              <div className="comparison-card">Pro</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Plans Price Monthly</div>
              <div className="comparison-card">47{currency}</div>
              <div className="comparison-card">87{currency}</div>
              <div className="comparison-card">147{currency}</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Plans Price Annual 30% off</div>
              <div className="comparison-card">394{currency}</div>
              <div className="comparison-card">734{currency}</div>
              <div className="comparison-card">1234{currency}</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Saving</div>
              <div className="comparison-card">170{currency}</div>
              <div className="comparison-card">310{currency}</div>
              <div className="comparison-card">530{currency}</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Auto Comment with AI</div>
              <div className="comparison-card">5/day (less filter)</div>
              <div className="comparison-card">20/day</div>
              <div className="comparison-card">50/day</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">
                Connexion with new prospects
              </div>
              <div className="comparison-card">20/day</div>
              <div className="comparison-card">50/day</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Saved Facebook Groups</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">
                Auto message on Friend Requests
              </div>
              <div className="comparison-card">10/day</div>
              <div className="comparison-card">25/day</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Smart Messages Algorithme</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Delete Pending Requests</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Auto Birthday’s Messages</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Wish Yesterday Birthdays</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Wish 2 Days ago Birthdays</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Contacts</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Tags</div>
              <div className="comparison-card">3</div>
              <div className="comparison-card">5</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Steps</div>
              <div className="comparison-card">10</div>
              <div className="comparison-card">50</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">CRM Messages</div>
              <div className="comparison-card">20/day</div>
              <div className="comparison-card">50/day</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">NovaData Usage</div>
              <div className="comparison-card">1 time /mo</div>
              <div className="comparison-card">2 time /mo</div>
              <div className="comparison-card">Unlimited</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Add to Whitelist</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Auto Unfriend</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Delete Deactivated friends</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
              <div className="comparison-card">✅</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">Support Email</div>
              <div className="comparison-card">Yes</div>
              <div className="comparison-card">Yes</div>
              <div className="comparison-card">Yes</div>
            </div>
            <div className="comparison-grid">
              <div className="comparison-card">
                1 Hour Startup Support on Zoom (in a small group)
              </div>
              <div className="comparison-card">Free</div>
              <div className="comparison-card">Free</div>
              <div className="comparison-card">Free</div>
            </div>
            <div className="comparison-grid comparison-grid-unlimited">
              <div className="comparison-card"></div>
              <div className="comparison-card">
                <button
                  disabled={btn1Status}
                  onClick={() => handleBuyNow(plan1?.item_price.id)}
                  className={`btn-outlined-purple ${
                    btn1Status && "faded-text"
                  }`}
                >
                  Choose Starter
                </button>
              </div>
              <div className="comparison-card">
                <button
                  disabled={btn3Status}
                  onClick={() => handleBuyNow(plan2?.item_price.id)}
                  className={`btn-outlined-purple ${
                    btn3Status && "faded-text"
                  }`}
                >
                  Choose Pro
                </button>
              </div>
              <div className="comparison-card btn-unlimited">
                <button
                  disabled={btn2Status}
                  onClick={() => handleBuyNow(plan3?.item_price.id)}
                  className={`btn-outlined-purple btn-grd ${
                    btn2Status && "faded-text"
                  }`}
                >
                  Choose Unlimited
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upgradepriceplan;
