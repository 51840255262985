import React, { useState, useEffect } from "react";
import "./Home.css";
import morningImage from "./img/morning.jpg";
import eveningImage from "./img/evening.jpg";
import nightImage from "./img/night.jpg";
import offBadgeImg from "./img/off-badge.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowForwardIosRounded from "@mui/icons-material/ArrowForward";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import {
  ADMIN_BASE_URL,
  BASE_URL,
  fetchPlanLimitDetails,
} from "backendServices/ApiCalls";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useModalState } from "app/hooks/use-modal-state";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";

const BackupExtensionDashboard = () => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [city, setCity] = useState("");
  const [greeting, setGreeting] = useState("");
  const [image, setImage] = useState("");
  const [showVideo, setShowVideo] = useState(true);
  const [planLimit, setPlanLimit] = useState({});
  const useraparofile = `${BASE_URL}uploads/userprofile/${planLimit?.new_packages?.picture}`;
  const updateExtensionModelState = useModalState();
  const { t } = useTranslation();
  const [values, setValues] = React.useState({
    extension_version: "1.3.12",
  });
  const updateExtensionModal = useModalState();
  const navigate = useNavigate();

  function fetchExtensionVersion() {
    axios
      .get(`${ADMIN_BASE_URL}/getadminaccountsettings`, {})
      .then((response) => {
        if (response?.data?.status === "success") {
          const result = response?.data?.data[0];
          if (result) {
            setValues({
              extension_version: result?.extension_version.trim(),
            });
          }
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    updateExtensionModal.onOpen();
    const updateDateTimeAndGreeting = () => {
      const now = new Date();
      const hours = now.getHours();
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      }).format(now);
      const formattedTime = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(now);

      setDate(formattedDate);
      setTime(formattedTime);

      if (hours >= 5 && hours < 12) {
        setGreeting("Morning");
        setImage(morningImage);
      } else if (hours >= 12 && hours < 17) {
        setGreeting("Afternoon");
        setImage(eveningImage);
      } else {
        setGreeting("Night");
        setImage(nightImage);
      }
    };
    fetchExtensionVersion();
    // Update the date, time, and greeting every minute
    const timer = setInterval(updateDateTimeAndGreeting, 60000);
    updateDateTimeAndGreeting(); // Initial update
    fetchPlanLimitDetailsFunction();
    // Get the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchCityName(position.coords.latitude, position.coords.longitude);
        },
        (error) => {}
      );
    }

    // Cleanup
    return () => clearInterval(timer);
  }, []);

  const fetchCityName = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCity(
        data.address.country ||
          data.address.city ||
          data.address.town ||
          data.address.village ||
          ""
      );
    } catch (error) {
      setCity("");
    }
  };

  const fetchPlanLimitDetailsFunction = async () => {
    let params = {};
    fetchPlanLimitDetails(
      params,
      (response) => {
        if (response?.data?.data) setPlanLimit(response?.data?.data);
      },
      (error) => {}
    );
  };

  const redirectToUpgradePlan = () => {
    navigate("/upgrade-price-plan");
  };

  return (
    <div className="dashboard-home">
      <AppBar
        position="static"
        sx={{ backgroundColor: "pink", display: "none" }}
        id="update-extension-bar"
        data-version={values.extension_version}
      >
        <Container maxWidth="xl" sx={{ display: "flex", p: 2 }}>
          <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
            {t("pages.title.update_extension")}
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{ flexGrow: 0, marginLeft: "10px" }}
            endIcon={<DownloadForOfflineIcon />}
            onClick={updateExtensionModelState.onOpen}
          >
            {t("pages.title.update_now")}{" "}
          </Button>
        </Container>
      </AppBar>
      <div className="dashboard-grid">
        <div className="left-menu">
          <div className="left-menu-box"></div>
        </div>
        <div className="content">
          {/* <div className="profile-box">
            <div className="img-box">
              <img src={useraparofile || pfpImg} alt="" />
            </div>
            <div className="profile-details">
              <h4>{planLimit?.new_packages?.firstname}</h4>
            </div>

            <div className="welcome-box">
              {" "}
              <div>
                <h2>
                  {date} <span></span> {time}
                </h2>
                <h3>
                  {" "}
                  <LocationOnOutlinedIcon /> {city}
                </h3>
              </div>
              <h1>What a Perfect {greeting} to Make Sales!</h1>
              <img src={image} alt={greeting} />
            </div>
          </div> */}
          <div className="content-grid">
            <div className="cg-left">
              <div className="svideo-box">
                <h2
                  onClick={() => {
                    setShowVideo((prevShowVideo) => !prevShowVideo);
                  }}
                >
                  {showVideo ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  Setting Video
                </h2>
                {showVideo ? (
                  <iframe
                    className={`video-transition ${
                      showVideo ? "video-visible" : ""
                    }`}
                    width="853"
                    height="480"
                    src="https://player.vimeo.com/video/867097113?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    title="Novalya - Dashboard tour"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                ) : null}
              </div>
              <div className="stats-grid">
                <div className="stats-card">
                  <p>Connects</p>
                  <h5>
                    {planLimit?.userlimit?.no_friend_request || 0}{" "}
                    <span> / </span>{" "}
                    {planLimit?.new_packages?.no_friend_request == 9999 ? (
                      <AllInclusiveIcon />
                    ) : (
                      planLimit?.new_packages?.no_friend_request || 0
                    )}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>Requests</p>
                  <h5>
                    {planLimit?.userlimit?.no_friend_requests_received || 0}{" "}
                    <span> / </span>{" "}
                    {planLimit?.new_packages?.no_friend_requests_received ==
                    9999 ? (
                      <AllInclusiveIcon />
                    ) : (
                      planLimit?.new_packages?.no_friend_requests_received || 0
                    )}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>Birthdays</p>
                  <h5>
                    {planLimit?.userlimit?.no_of_birthday_wishes || 0}{" "}
                    <span> / </span>{" "}
                    {planLimit?.new_packages?.no_of_birthday_wishes == 9999 ? (
                      <AllInclusiveIcon />
                    ) : (
                      planLimit?.new_packages?.no_of_birthday_wishes || 0
                    )}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>AI Comments</p>
                  <h5>
                    {planLimit?.userlimit?.no_ai_comment || 0} <span> / </span>{" "}
                    {planLimit?.new_packages?.no_ai_comment == 9999 ? (
                      <AllInclusiveIcon />
                    ) : (
                      planLimit?.new_packages?.no_ai_comment || 0
                    )}{" "}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>Contacts</p>
                  <h5>
                    {" "}
                    {planLimit?.totalUsers || 0} <span> / </span>{" "}
                    <AllInclusiveIcon />{" "}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>Tags</p>
                  <h5>
                    {planLimit?.tagCount || 0} <span> / </span>{" "}
                    {planLimit?.new_packages?.no_crm_group == 9999 ? (
                      <AllInclusiveIcon />
                    ) : (
                      planLimit?.new_packages?.no_crm_group || 0
                    )}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>Stages</p>
                  <h5>
                    {planLimit?.stageCount || 0} <span> / </span>{" "}
                    {planLimit?.new_packages?.no_stages_group == 9999 ? (
                      <AllInclusiveIcon />
                    ) : (
                      planLimit?.new_packages?.no_stages_group || 0
                    )}{" "}
                  </h5>
                </div>
                <div className="stats-card">
                  <p>NovaData</p>
                  <h5>
                    {planLimit?.new_packages?.advanced_novadata || 0}
                    <span> / </span> 2
                  </h5>
                </div>
              </div>
            </div>
            <div className="cg-right">
              <div className="system-box">
                <div className="status-box">
                  <h5> Software Status </h5>
                  <div className="tag active-ext" style={{ display: "none" }}>
                    <div></div>
                    active
                  </div>
                  <div className="inactive-tag inactive-ext">
                    <div></div>
                    in active
                  </div>
                </div>
                <a href="#" className="active-ext" style={{ display: "none" }}>
                  Start Prospecting <ArrowForwardIosRounded />
                </a>
                <a
                  className="install-extension-btn inactive-tag inactive-ext"
                  onClick={() => {
                    window.open(
                      "https://chrome.google.com/webstore/detail/novalya/iemhbpcnoehagepnbflncegkcgpphmpc",
                      "_blank"
                    );
                  }}
                >
                  Install Extension
                </a>
              </div>
              <div className="system-box">
                <div className="status-box">
                  <h5> Chrome Authorization </h5>
                  <div
                    className="tag chrome-active-ext"
                    style={{ display: "none" }}
                  >
                    <div></div>
                    active
                  </div>
                  <div className="inactive-tag chrome-inactive-ext">
                    <div></div>
                    in active
                  </div>
                </div>
                <div style={{ display: "flex", gap: "8px" }}>
                  <a id="facebook-chrome">Facebook</a>
                  <a
                    className="install-extension-btn inactive-tag"
                    id="messenger-chrome"
                  >
                    Messenger
                  </a>
                  <a
                    className="install-extension-btn inactive-tag"
                    id="instagram-chrome"
                  >
                    Instagram
                  </a>
                </div>
              </div>
              <div className="system-box">
                <div className="status-box">
                  <h5> Connect your profile </h5>
                </div>
                <a id="connect-facebook">Connect your facebook profile</a>
                <a id="connect-instagram">Connect your instagram profile</a>
              </div>
              <div
                className="system-box"
                style={{ display: "flex", gap: "8px", paddingTop: 0 }}
              >
                <a id="facebook-permission">
                  Facebook <ArrowForwardIosRounded />
                </a>
                <a
                  className="install-extension-btn inactive-tag"
                  id="messenger-permission"
                >
                  Messenger <ArrowForwardIosRounded />
                </a>
              </div>
              <div className="upgrade-box">
                <img src={offBadgeImg} alt="" />
                <p>
                  {planLimit?.planDetails?.sub_type == "month"
                    ? "Given your current price plan, you get 30% OFF on annual plan if you upgrade now."
                    : "Given your current plan, you’re eligible to Unlimited Plan Feature."}
                </p>
                <div className="system-box">
                  <a onClick={redirectToUpgradePlan}>Upgrade Now</a>
                </div>
              </div>
              <div className="system-box">
                {planLimit?.new_packages?.user_type == "Distributor" ? (
                  <>
                    <h4>Promote & Get Paid</h4>
                    <p>
                      By sharing your affiliate link to others you get paid up
                      to 63%. Share it to the world now.
                    </p>
                    <Link to="/affiliate-links">
                      My Affiliate Links <ArrowForwardIosRounded />
                    </Link>
                  </>
                ) : (
                  <>
                    <h4>Want Some Extra Money?</h4>
                    <p>
                      Join our affiliate program to get paid up to 63% and have
                      access to amazing marketing tools.
                    </p>
                    <Link to="/dashboard">
                      Become An Affiliate <ArrowForwardIosRounded />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={updateExtensionModelState.onClose}
        aria-labelledby="customized-dialog-title"
        open={updateExtensionModelState.isOpen}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {t("pages.title.dashboard_subtitle3")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={updateExtensionModelState.onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <iframe
            style={{ height: "280px", width: "500px" }}
            src="https://player.vimeo.com/video/829270454?h=0fb148f400&badge=0&autopause=0&player_id=0&app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            title="Paramétrage et Démonstration Novalya - Dashboard - French"
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" id="update_ext">
            {t("pages.title.dashboard_subtitle3")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BackupExtensionDashboard;
