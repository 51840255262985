import React, { Suspense } from "react";
import PropTypes from "prop-types";

import { CssBaseline, IconButton, Toolbar } from "@mui/material";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import {
  SIDEBAR_STYLES,
  SIDEBAR_VARIANTS,
  SIDEBAR_VIEWS,
} from "@jumbo/utils/constants/layout";
import JumboLayoutHeader from "./JumboLayoutHeader";
import JumboLayoutFooter from "./JumboLayoutFooter";
import JumboLayoutSidebar from "./JumboLayoutSidebar";
import Div from "@jumbo/shared/Div";
import useJumboLayoutHeader from "@jumbo/hooks/useJumboLayoutHeader";
import useJumboLayoutRoot from "@jumbo/hooks/useJumboLayoutRoot";
import useJumboLayoutContent from "@jumbo/hooks/useJumboLayoutContent";
import { useContext } from "react";
import { AppContext } from "app/AppContext";
import { Topbar } from "app/pages/TopBar";
import SidebarSkeleton from "app/layouts/shared/sidebars/Sidebar/SidebarSkeleton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const JumboLayout = (props) => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { headerOptions } = useJumboLayoutHeader();
  const { rootOptions } = useJumboLayoutRoot();
  const { contentOptions } = useJumboLayoutContent();
  const { checkLogin } = useContext(AppContext);
  const LoginStatus = checkLogin();

  const contentMargin = React.useMemo(() => {
    if (sidebarOptions?.variant === SIDEBAR_VARIANTS.TEMPORARY) {
      return 0;
    } else if (sidebarOptions?.view === SIDEBAR_VIEWS.MINI) {
      return sidebarOptions?.minWidth;
    }
    if (LoginStatus) {
      return sidebarOptions?.open ? sidebarOptions?.width : 0;
    } else {
      return sidebarOptions?.hide ? sidebarOptions?.width : 0;
    }
  }, [
    sidebarOptions?.open,
    sidebarOptions?.width,
    sidebarOptions?.minWidth,
    sidebarOptions?.view,
    sidebarOptions?.style,
    sidebarOptions?.variant,
  ]);

  const headerHeightProps = React.useMemo(() => {
    if (props?.headerSx?.height) {
      return { height: props?.headerSx?.height };
    }
    return {};
  }, [props?.headerSx]);

  return (
    <Div
      sx={{
        display: "flex",
        flex: 1,
        minWidth: 0,
        minHeight: "100%",
        flexDirection: "column",
        ...rootOptions?.sx,
      }}
      className="CmtLayout-root"
    >
      <CssBaseline />
      {/* {LoginStatus &&
        sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
          <JumboLayoutHeader sx={props.headerSx}>
            {props.header}
          </JumboLayoutHeader>
        )} */}
      <Suspense
        fallback={
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              alignContent: "center",
              px: 3,
            }}
          >
            <SidebarSkeleton />
          </Div>
        }
      >
        {/* {LoginStatus && <Topbar />} */}
      </Suspense>

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          position: "relative",
        }}
        className="CmtLayout-wrapper"
      >
        {LoginStatus && (
          <JumboLayoutSidebar headerHeightProps={headerHeightProps}>
            {props.sidebar}
          </JumboLayoutSidebar>
        )}

        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            flex: 1,
            flexDirection: "column",
            minHeight: "100%",
            marginLeft: {
              sm: `${contentMargin}px`,
            },
            transition: (theme) => theme.transitions.create(["margin-left"]),
          }}
          className="CmtLayout-main"
        >
          {/* {LoginStatus &&
            sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
              <JumboLayoutHeader sx={props.headerSx}>
                {props.header}
              </JumboLayoutHeader>
            )} */}
          {/* {/* {LoginStatus && !headerOptions.hide && headerOptions.fixed && (
            <Toolbar sx={{ ...headerHeightProps }} />
          )} */}
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              py: 3,
              px: { lg: 6, xs: 4 },
              ...(contentOptions?.sx ?? {}),
            }}
            className="CmtLayout-content"
          >
            {!sidebarOptions?.open && LoginStatus && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                style={{
                  border: "1px solid #D8D8D8",
                  position: "absolute",
                  left: "-3px",
                  zIndex: 11,
                  background: "rgb(255, 255, 255)",
                }}
                onClick={() => setSidebarOptions({ open: !sidebarOptions?.open })}
              >
                <ArrowForwardIosIcon style={{ fontSize: "1rem" }} />
              </IconButton>
            )}

            {props.children}
          </Div>
          <JumboLayoutFooter>{props.footer}</JumboLayoutFooter>
        </Div>
      </Div>
    </Div>
  );
};

JumboLayout.propTypes = {
  header: PropTypes.node,
  headerSx: PropTypes.object,
  sidebar: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

export default JumboLayout;
