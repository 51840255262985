// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabmenu-btn {
  min-height: 44px;
  border-radius: 8px;
  background:  rgb(44 115 255 / 10%);
  color: #637381;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: none !important;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  padding: 5px;
}
.tabmenu-btn svg {
  width: 24px;
}

.tabmenu-btn:hover {
  background: rgb(44 115 255 / 100%);
  color: #fff;
}

.tabmenu-btn:hover .only-stroke path {
  stroke: #fff;
}
.tabmenu-btn:hover .only-fill path {
  fill: #fff;
}

.tabmenu-btn.tab-menu-active {
  color: #fff;
  background: rgb(44 115 255 / 100%);
}

.tabmenu-btn.tab-menu-active .only-stroke path {
  stroke: #fff;
}
.tabmenu-btn.tab-menu-active .only-fill path {
  fill: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/components/mui/TabMenu/tabmenu-new.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kCAAkC;EAClC,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,uBAAuB;EACvB,eAAe;EACf,YAAY;AACd;AACA;EACE,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,WAAW;AACb;;AAEA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ","sourcesContent":[".tabmenu-btn {\n  min-height: 44px;\n  border-radius: 8px;\n  background:  rgb(44 115 255 / 10%);\n  color: #637381;\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  box-shadow: none !important;\n  width: 100%;\n  justify-content: center;\n  font-size: 14px;\n  padding: 5px;\n}\n.tabmenu-btn svg {\n  width: 24px;\n}\n\n.tabmenu-btn:hover {\n  background: rgb(44 115 255 / 100%);\n  color: #fff;\n}\n\n.tabmenu-btn:hover .only-stroke path {\n  stroke: #fff;\n}\n.tabmenu-btn:hover .only-fill path {\n  fill: #fff;\n}\n\n.tabmenu-btn.tab-menu-active {\n  color: #fff;\n  background: rgb(44 115 255 / 100%);\n}\n\n.tabmenu-btn.tab-menu-active .only-stroke path {\n  stroke: #fff;\n}\n.tabmenu-btn.tab-menu-active .only-fill path {\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
