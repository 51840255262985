import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Avatar, TextField } from "@mui/material";

export function ForthStep({ onChange, groups, groupData, setActionDataType }) {
  const { t } = useTranslation();
  const [action, setAction] = useState(
    groupData?.action ? JSON.parse(groupData?.action) : "no"
  );
  const [group, setActiveGroup] = useState();
  const [stages, setStages] = useState([]);
  const [stage, setActiveStage] = useState();
  const [searchText, setSearchText] = useState(""); // New state for search text
  const [stage_num, setStageNum] = useState();
  let actionOptions = [
    { value: "no", text: t("pages.title.no_action") },
    { value: "message", text: t("pages.title.add_to_group") },
  ];

  useEffect(() => {
    const actionValue = groupData.action ? JSON.parse(groupData.action) : "";
    if (actionValue !== "no" && actionValue?.moveGroupId) {
      const index = groups.findIndex(
        (group) => group.id === actionValue.moveGroupId
      );

      if (index) {
        const grp =
          typeof groups[index] != "undefined" ? groups[index] : groups[0];
        const stage1 = grp ? grp.stage : "";
        setActiveGroup(grp?.id);
        setStages(stage1);
        setActiveStage(groups ? (stage1 ? grp.stage[0]?.id : 0) : 0);
        setStageNum(groups ? (stage1 ? grp.stage[0]?.stage_num : 0) : 0);
      }
      handleActionChange("message");
      handleGroupChange(index);
    } else {
      handleActionChange("no");
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const setInititalValues = (value) => {
    setActiveGroup(groups ? groups[0]?.id : 0);
    setStages(groups ? groups[0]?.stage : []);
    setActiveStage(
      groups ? (groups[0]?.stage ? groups[0]?.stage[0]?.id : 0) : 0
    );
    setStageNum(
      groups ? (groups[0]?.stage ? groups[0]?.stage[0]?.stage_num : 0) : 0
    );
  };

  useEffect(() => {
    onChange({
      action: JSON.stringify({
        moveStageId: action === "no" ? null : stage,
        moveGroupId: action === "no" ? null : group,
        stage_num: action === "no" ? null : stage_num,
      }),
    });
  }, [stage]);

  const handleActionChange = (value) => {
    setAction(value);

    if (value === "no") {
      onChange({
        action: JSON.stringify({
          moveStageId: null,
          moveGroupId: null,
          stage_num: null,
        }),
      });
      setActionDataType("no");
    } else {
      setActionDataType("YES");
      setInititalValues();
    }
  };

  const handleGroupChange = (value) => {
    const grp = typeof groups[value] != "undefined" ? groups[value] : groups[0];
    setActiveGroup(grp?.id);
    const stage1 = grp ? grp.stage : "";
    setStages(stage1);
    let stageId = grp?.stage[0]?.id || 0;
    setActiveStage(stageId);
    setStageNum(grp?.stage[0]?.stage_num || 0);
    handleChange();
  };
  const handleStageChange = (value) => {
    const stg = stages[value];
    setActiveStage(stg.id);
    setStageNum(stg.stage_num || 0);

    onChange({
      action: JSON.stringify({
        moveStageId: stg.id,
        moveGroupId: group,
        stage_num: stg.stage_num,
      }),
    });
  };

  const handleChange = () => {
    onChange({
      action: JSON.stringify({
        moveStageId: action==="no" ? null : stage,
        moveGroupId: action==="no" ? null : group,
        stage_num: action === "no" ? null : stage_num,
      }),
    });
  };
  return (
    <div className="">
      <span className="title-18 action-title">
        {t("pages.title.select_action")}
        <TextField
          type="text"
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search groups"
        />
      </span>

      <div className="col-2-2 action-wraper">
        <div className="col-2-2">
          {actionOptions.map((item, index) => {
            return (
              <div className="col_1 ctm-col-wraper" key={index}>
                <div className="message-right">
                  <label className="group-items" htmlFor={`action-${item.value}`}>
                    <input
                      type="radio"
                      id={`action-${item.value}`}
                      name="action"
                      checked={item.value===action ? true : false}
                      onChange={() => handleActionChange(item.value)}
                    />
                    <div className="group-wraper-right">
                      <span className="group-name">{item.text}</span>
                    </div>
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        {action === "no" ? (
          <div className="col_1">
            <div className="action-shadow-box 1">
              <span>{t("pages.title.no_action_select")}</span>
            </div>
          </div>
        ) : (
          <div className="col_1">
            <div className="action-shadow-box 2" data-action={action}>
              <div className="message-action">
                <div className="action-list">
                  {groups
                    ?.filter((item) =>
                      item.name.toLowerCase().includes(searchText.toLowerCase())
                    )
                    ?.map((item, index) => {
                      return (
                        <>
                          <span
                            className={`action-tags ${
                              item.id===group ? "green-blue" : ""
                            }`}
                            onClick={() => handleGroupChange(index)}
                            key={index}
                          >
                            <Avatar
                              sx={{
                                cursor: "pointer",
                                backgroundColor: item.custom_color,

                                width: 40,
                                height: 40,
                                mr: 1,
                                borderRadius: "11px",
                              }}
                            >
                              {item.name.charAt(0).toUpperCase()}
                              {item.name.charAt(1).toUpperCase()}
                            </Avatar>{" "}
                            {item.name}
                          </span>
                        </>
                      );
                    })}
                </div>
                <div className="action-list date-picker-action">
                  {(stages || [])
                    .sort((a, b) => a.stage_num - b.stage_num)
                    .map((item, index) => {
                      return (
                        <span
                          className={`action-tags ${
                            item.id === stage ? "green-blue" : ""
                          }`}
                          onClick={() => handleStageChange(index)}
                          key={index}
                        >
                          {item.name}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
